.fullscreen-blade {
    width: 100% !important;
    height: 100% !important;
}

.default-blade-width {
    width: 500px;
    min-width: min-content;
}

.mat-mdc-form-field:not(.mat-form-field-appearance-legacy)
    .mat-mdc-form-field-icon-suffix
    .mat-mdc-icon-button,
.mat-mdc-form-field:not(.mat-form-field-appearance-legacy)
    .mat-mdc-form-field-icon-prefix
    .mat-mdc-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-prefix
    .mat-icon-button {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
}

/* added for .mat-form-field-flex*/
/* нельзя давать здесь общий 1 стиль */
/* .mat-mdc-form-field-flex  {
    padding: 0 0.75em 0 0.75em;
    position: relative;
} */

mat-radio-group {
    display: contents;
}

/* Checkboxes */

.mat-mdc-checkbox .mdc-form-field .mdc-checkbox {
    padding: 0;
    margin: 0;
    width: 16px;
    height: 16px;
}

.mat-mdc-checkbox .mat-mdc-checkbox-touch-target {
    width: 16px;
    height: 16px;
}

.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control {
    width: 16px;
    height: 16px;
}

.mat-mdc-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
}

.mat-mdc-checkbox
    .mdc-form-field
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
.mat-mdc-checkbox
    .mdc-form-field
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    @apply bg-info-main !important;
    @apply border-info-main !important;
}

.mat-mdc-checkbox
    .mdc-form-field
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    @apply text-white;
}

.mat-mdc-checkbox
    .mdc-form-field
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    @apply border-white;
}
.mat-mdc-checkbox.mat-accent
    .mdc-form-field
    .mdc-checkbox--selected
    ~ .mdc-checkbox__ripple {
    @apply bg-info-main;
}

/* Radiobuttons */

/* Base */

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
.mat-mdc-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked
    .mat-radio-persistent-ripple,
.mat-mdc-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    @apply bg-info-main !important;
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked
    .mdc-radio__outer-circle {
    @apply border-info-main !important;
}

/*.mdc-radio__outer-circle {*/
/*    @apply border-black !important;*/
/*}*/

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
    @apply border-info-main !important;
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
.mat-mdc-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked
    .mat-radio-persistent-ripple,
.mat-mdc-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    @apply bg-info-main !important;
}

.mat-mdc-radio-button.mat-radio-disabled.mat-mdc-radio-checked
    .mdc-radio__outer-circle,
.mat-mdc-radio-button.mat-radio-disabled .mdc-radio__outer-circle {
    @apply border-neutral-main !important;
}

.mat-mdc-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-mdc-radio-button.mat-radio-disabled .mdc-radio__inner-circle {
    @apply bg-neutral-main !important;
}

.mat-mdc-radio-button .mdc-radio {
    @apply p-0 !important;
}

.mdc-form-field label {
    @apply pl-0 !important;
}

/* Text inputs */
/* Base*/

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin-top: 0.25em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}
/* added mdc type*/
.mat-form-field-appearance-outline .mat-mdc-form-field-icon-suffix,
.mat-form-field-appearance-outline .mat-mdc-form-field-icon-prefix,
.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: unset !important;
    align-self: center;
}

.mat-form-field-infix {
    @apply border-t-0 !important;
}

.mat-form-field-appearance-outline:not(
        .mat-form-field-invalid,
        .mat-focused,
        .mat-form-field-disabled
    )
    .mdc-notched-outline__notch,
.mat-form-field-appearance-outline:not(
        .mat-form-field-invalid,
        .mat-focused,
        .mat-form-field-disabled
    )
    .mdc-notched-outline__leading,
.mat-form-field-appearance-outline:not(
        .mat-form-field-invalid,
        .mat-focused,
        .mat-form-field-disabled
    )
    .mdc-notched-outline__trailing {
    @apply border-neutral-lighter !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__notch,
.mat-form-field-appearance-outline .mdc-notched-outline__leading,
.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
    @apply rounded-none !important;
    @apply border-2 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    @apply text-neutral-lighter !important;
}
/* Changed placeholder color and font-weight */
input.mat-mdc-input-element::placeholder {
    color: #0000006b;
    font-family: Roboto, Helvetica Neue, sans-serif;
    letter-spacing: normal;
}

input.mat-mdc-input-element,
textarea.mat-input-element {
    margin-top: 0 !important;
}

.mat-form-field-appearance-outline .mat-date-range-input-container {
    padding: 8px 0;
}

/* textarea.mat-input-element {
    margin-bottom: 10px !important;
} */

.mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0 !important;
}

/* added mdc type */
.mat-form-field-appearance-outline .mat-mdc-form-field-infix,
.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0 !important;
    min-height: 0 !important;
    align-self: center;
}

/* added mdc type */
.mat-form-field-appearance-outline .mat-mdc-form-field-infix input,
.mat-form-field-appearance-outline .mat-form-field-infix input,
.mat-form-field-appearance-outline .mat-mdc-form-field-infix textarea,
.mat-form-field-appearance-outline .mat-form-field-infix textarea,
.mat-form-field-appearance-outline mat-select {
    padding: 8px 0 8px 0 !important;
    background: transparent;
    outline: none;
    width: 100%;
}

.mat-mdc-select-arrow-wrapper {
    height: unset !important;
}

.mat-form-field-appearance-outline .mat-select-trigger {
    vertical-align: middle;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: unset !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    @apply opacity-100 !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    @apply text-info-extra-dark !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled:not([readonly])
    .mat-form-field-infix
    input,
.mat-form-field-appearance-outline.mat-form-field-disabled:not([readonly])
    .mat-form-field-infix
    textarea {
    @apply text-neutral-main !important;
    @apply opacity-30 !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-outline-thick {
    @apply bg-neutral-extra-extra-lighter !important;
    @apply text-neutral-extra-light !important;
}

.mat-form-field-appearance-outline[readonly] .mat-form-field-outline-thick {
    @apply bg-neutral-extra-extra-lighter !important;
    @apply text-neutral-extra-light !important;
}

.mat-form-field-appearance-outline:not(.mat-focused)[readonly]
    .mat-form-field-infix
    input,
.mat-form-field-appearance-outline:not(.mat-focused)[readonly]
    .mat-form-field-infix
    textarea {
    @apply text-secondary-extra-light !important;
}

.mat-form-field-appearance-outline.ng-valid[validated]:not(.mat-focused)
    .mat-form-field-outline-thick {
    @apply text-success-main !important;
}

.mat-form-field-appearance-outline.ng-invalid.ng-dirty:not(.mat-focused)
    .mat-form-field-outline-thick {
    @apply text-danger-main !important;
}

/* mdc added*/
/* .mat-form-field-wrapper, .mat-mdc-text-field-wrapper {
    @apply pb-0 !important;
} */

/* slide toggle */
.mat-slide-toggle-bar {
    @apply bg-secondary-extra-light !important;
}
.mat-slide-toggle-label {
    align-items: flex-start !important;
    height: auto !important;
}
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-checked .mat-ripple-element {
    @apply bg-info-main !important;
}

.mat-slide-toggle-thumb {
    border-width: 1px !important;
    height: 19px;
    width: 19px !important;
    @apply bg-white !important;
    @apply border-solid !important;
}

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    @apply border-info-main !important;
}

.mat-mdc-slide-toggle:not(.mat-checked) .mat-slide-toggle-thumb {
    @apply border-secondary-extra-light !important;
}

.mat-slide-toggle-thumb-container {
    top: 0 !important;
    left: 1px !important;
}

.mat-slide-toggle-bar {
    border-radius: 10px !important;
    @apply h-20 !important;
}
/*mat-progress-bar*/
.mat-progress-bar-fill::after {
    @apply bg-accent-main !important;
}

.mat-progress-bar-buffer {
    @apply bg-accent-extra-lighter !important;
}
/* mat-tabs */

/* .mat-ink-bar was in 13 version instead of .mdc-tab-indicator__content--underline in 15 version */
.mat-mdc-tab-group.mat-primary .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-primary .mdc-tab-indicator__content--underline {
    @apply bg-info-main !important;
    @apply h-4 !important;
}

.mat-mdc-tab:not(.mdc-tab--active) .mdc-tab__content .mdc-tab__text-label {
    @apply text-info-main !important;
    @apply underline;
}

.mdc-tab--active .mdc-tab__content .mdc-tab__text-label {
    @apply font-bold  !important;
    color: #0009 !important;
}

.mat-mdc-tab .mdc-tab__content .mdc-tab__text-label {
    @apply text-label-1 !important;
    @apply font-roboto !important;
}

.mat-mdc-tab-body-wrapper .mat-mdc-tab-body:not(.mat-mdc-tab-body-active) {
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

/* paginator */
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
    @apply text-accent-main !important;
}

.mat-mdc-paginator {
    background: transparent !important;
    @apply text-paragraph-3 !important;
}

.mat-mdc-paginator,
.mat-mdc-paginator-page-size .mat-select-trigger {
    color: #0000008a !important;
}
/* form */
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
    @apply bg-accent-main !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 1.5em 0 !important;
}

.mat-date-range-input-start-wrapper {
    position: unset !important;
}
/* dialog */
.mat-mdc-dialog-container .mdc-dialog__title {
    @apply flex font-roboto font-medium text-header-5 leading-tight tracking-normal !important;
    color: #000000de !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    @apply font-roboto text-paragraph-2 font-normal leading-tight tracking-normal !important;
    color: #000000de !important;
}

.mdc-dialog__surface {
    @apply p-24;
}
/* menu */
.mat-mdc-menu-item {
    @apply text-label-1 !important;
}

/* tooltip */
.mdc-tooltip__surface {
    @apply text-label-2 !important;
}
