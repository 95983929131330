@layer base {
    html {
        height: 100%;
    }

    html,
    body {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 0;
    }

    body {
        font-family: Roboto;
    }

    .component,
    ng-component,
    router-outlet {
        display: contents !important;
    }
}

@layer components {
    .dark-shimmer {
        @apply shimmer-accent-primary-extra-extra-lighter shimmer-neutral-lighter;
    }
}

/* devexpress data-grid */
.dx-datagrid .dx-column-lines > td {
    border: none !important;
}

.dx-datagrid-headers {
    @apply text-label-2 #{!important};
    @apply font-bold #{!important};
    @apply py-4 #{!important};
    @apply text-black #{!important};
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: none !important;
}

.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
    border-top: none !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    vertical-align: inherit !important;
}

td {
    @apply px-12 #{!important};
}

.dx-header-row td {
    @apply py-10 #{!important};
}

.dx-datagrid-nodata {
    @apply text-black #{!important};
    @apply font-paragraph-2 #{!important};
    @apply text-paragraph-2 #{!important};
}

#cloudpayments-loading-overlay-63456123 > img {
    left: 50% !important;
}
