// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import './material.styles.scss';
@import '~cropperjs/dist/cropper.css';

@keyframes shimmerAnimation {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}
@-webkit-keyframes shimmerAnimation {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

shimmer {
    font-size: 0;
    display: inline-flex;
    align-items: stretch;
    overflow: hidden;

    .shimmer-loader {
        flex: 1 1 0;
        width: auto !important;
        height: auto !important;

        background: #f5f5f5;
        background-image: linear-gradient(
            90deg,
            #f5f5f5 0,
            #bdbdbd 20%,
            #f5f5f5 40%,
            #f5f5f5
        );
        background-repeat: no-repeat;
        background-size: 1000px 1000px;
    }

    //&.dark-shimmer {
    //    .shimmer-loader {
    //        background: #e0e0e0;
    //        background-image: linear-gradient(90deg, #e0e0e0 0, #edeef1 20%, #e0e0e0 40%, #e0e0e0);
    //        background-repeat: no-repeat;
    //        background-size: 1000px 1000px;
    //    }
    //}
}

[inputSuffix],
[inputPrefix] {
    ic-icon {
        font-size: 1.3em;
    }
}

html {
    line-height: 1.2 !important;
}

shared-ui-input .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    padding-right: 16px !important;
    padding-left: 16px !important;
}
